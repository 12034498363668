/** @jsx jsx */
import { jsx } from "theme-ui"
import { formatCardDate, toSlug } from "../helpers"
import collectionFlag from "../images/collection-flag.svg"
import Link from "./link"

const EventCard = ({ event, ...restProps }) => {
  return (
    <div {...restProps}>
      <Link
        link={`/event/${toSlug(event.eventName)}-${event.contentful_id}`}
        sx={{
          color: "initial",
          "&:hover": { color: ["unset", "unset", "primary"] },
        }}
      >
        <div sx={{ height: "100%", width: "100%" }}>
          <div
            sx={{
              backgroundImage:
                event.eventImage?.file?.url &&
                `url(${event.eventImage.file.url}?w=512&h=512&fit=fill)`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: "100%",
              "&:after": {
                content: "''",
                display: "block",
                pb: "100%",
              },
              position: "relative",
            }}
          >
            <div
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                display: "grid",
                height: "280px",
                gridTemplateColumns: event.collection
                  ? "max-content max-content"
                  : "max-content",
                columnGap: "2px",
              }}
            >
              {event.experience &&
                event.experience.some(
                  experience => experience.experienceName === "Online"
                ) && (
                  <div
                    sx={{
                      fontSize: "11px",
                      px: "10px",
                      letterSpacing: "1.5px",
                      height: "36px",
                      backgroundColor: "primary",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    ONLINE
                  </div>
                )}
              {event.collection && (
                <div
                  sx={{
                    width: "36px",
                    height: "36px",
                    backgroundColor: "primary",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  <img alt="" sx={{ width: "50%" }} src={collectionFlag} />
                </div>
              )}
            </div>
          </div>
          <div
            sx={{
              width: "100%",
              lineHeight: "18px",
              mt: "10px",
            }}
          >
            <div
              sx={{
                fontSize: "12px",
                textTransform: "uppercase",
                mt: "12px",
                mb: "12px",
              }}
            >
              /{event.discipline?.disciplineName}
            </div>
            <h4
              title={event.eventName}
              sx={{
                fontSize: "24px",
                lineHeight: "33px",
                mb: "18px",
                pr: "20px",
                maxWidth: "100%",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {event?.eventName}
            </h4>
            <p sx={{ m: 0, p: 0 }}>—</p>
            <p sx={{ fontSize: "13px", mb: 0 }}>
              {formatCardDate(event.eventStartDate, event.eventEndDate)}
            </p>
            {event.venue && (
              <p sx={{ fontSize: "13px", mb: "18px" }}>{event.venue.name}</p>
            )}
            {event.collection && (
              <div
                sx={{
                  pr: "20px",
                }}
              >
                <p
                  sx={{
                    color: "primary",
                    fontSize: "18px",
                    fontWeight: 500,
                    lineHeight: "22px",
                    mb: 0,
                    maxWidth: "100%",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 3,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {event.collection.name}
                </p>
                <p sx={{ fontSize: "13px", mb: 0 }}>
                  {formatCardDate(
                    event.collection.startDate,
                    event.collection.endDate
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default EventCard
