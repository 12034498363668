import Axios from "axios"
import React from "react"
import { useIsClient } from "./use-is-client"

export const useAd = url => {
  const isClient = useIsClient()
  const [response, setResponse] = React.useState(null)
  React.useEffect(() => {
    if (url && isClient && !response) {
      Axios.get(url)
        .then(response => {
          setResponse(response.data?.placements?.placement_1)
        })
        .catch(err => {})
    }
  }, [url, setResponse, isClient])
  return response
}
