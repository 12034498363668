/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import DetailHero from "./detail-hero"
import SpinnerSection from "./spinner-section"
import OrganizationInfoSection from "./event-form/organization-info-section"
import { useEventCalendarEvents } from "../hooks/use-event-calendar-events"
import EventList from "./event-list"
import Container from "./container"

const CollectionDetail = ({ collection, ...restProps }) => {
  const calendarQuery = React.useMemo(
    () =>
      collection
        ? {
            "fields.collection.sys.contentType.sys.id": "collection",
            "fields.collection.sys.id": collection.contentful_id,
          }
        : false,
    [collection]
  )
  const events = useEventCalendarEvents(calendarQuery)

  const venue = React.useMemo(() => {
    if (
      events &&
      events.timeFilteredTodayEvents &&
      events.weekEvents &&
      events.monthEvents &&
      events.groupedFutureEvents
    ) {
      let allEvents = [
        ...events.timeFilteredTodayEvents,
        ...events.weekEvents,
        ...events.monthEvents,
      ]
      for (let month of events.groupedFutureEvents) {
        allEvents = [...allEvents, ...month.events]
      }
      if (allEvents.length > 0) {
        const firstVenue = allEvents[0].fields.venue
        if (
          allEvents.every(
            event => event.fields.venue.sys.id === firstVenue.sys.id
          )
        ) {
          return firstVenue
        } else return false
      }
    }
    return null
  }, [events])

  return (
    <div
      sx={{
        background: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <DetailHero
        type={"venue"}
        entry={{
          image: collection.image && collection.image.file.url,
          discipline: collection.discipline?.disciplineName,
          name: collection.name,
          startDate: collection.startDate,
          endDate: collection.endDate,
          eventOrVenueUrl: collection.website && collection.website.website,
          venue: venue && venue.fields,
          description: collection.description?.description,
        }}
      />
      {events ? (
        <Container sx={{ pt: "20px", pb: 0 }}>
          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              pb: "30px",
              borderBottom: "1px solid #A5A5A5",
            }}
          >
            {events.timeFilteredTodayEvents &&
              events.timeFilteredTodayEvents.length > 0 && (
                <EventList
                  type="secondary"
                  events={events.timeFilteredTodayEvents}
                  collections={[]}
                  title={"Today"}
                />
              )}
            {events.weekEvents && events.weekEvents.length > 0 && (
              <EventList
                type="secondary"
                events={events.weekEvents}
                collections={[]}
                title={"This Week"}
              />
            )}
            {events.monthEvents && events.monthEvents.length > 0 && (
              <EventList
                type="secondary"
                events={events.monthEvents}
                collections={[]}
                title={"This Month"}
              />
            )}
            {events.groupedFutureEvents &&
              events.groupedFutureEvents.length > 0 &&
              events.groupedFutureEvents.map(
                (month, index) =>
                  month.events &&
                  month.events.length > 0 && (
                    <EventList
                      key={index}
                      type="secondary"
                      events={month.events}
                      collections={[]}
                      title={month.title}
                    />
                  )
              )}
          </div>
        </Container>
      ) : (
        <SpinnerSection />
      )}
      {collection.organization && (
        <OrganizationInfoSection organization={collection.organization} />
      )}
    </div>
  )
}

export default CollectionDetail
