import React from "react"
import * as moment from "moment-timezone"
import { useEventService } from "../services/event-service"
import { getEventCalendarDates } from "../helpers"

const useEventCalendarEvents = additionalQuery => {
  const [loading, setLoading] = React.useState(false)
  const { today, nextFriday, nextSunday, monthEnd } = React.useMemo(
    () => getEventCalendarDates(),
    []
  )
  const {
    findEvents: findTodayEvents,
    response: { data: todayEvents },
  } = useEventService()
  const {
    findEvents: findWeekEvents,
    response: { data: weekEvents },
  } = useEventService()
  const {
    findEvents: findMonthEvents,
    response: { data: monthEvents },
  } = useEventService()
  const {
    findEvents: findFutureEvents,
    response: { data: futureEvents },
  } = useEventService()

  React.useEffect(() => {
    if (additionalQuery !== false) {
      findTodayEvents({
        "fields.approved": true,
        "fields.organization.sys.contentType.sys.id": "organization",
        "fields.organization.fields.approved": true,
        "fields.eventStartDate[lte]": today,
        "fields.eventEndDate[gte]": today,
        ...additionalQuery,
      })
      setLoading(true)
    }
  }, [additionalQuery, findTodayEvents, today])

  const timeFilteredTodayEvents = React.useMemo(
    () =>
      todayEvents &&
      todayEvents.filter(event => {
        if (event.fields.allDayEvent) return true
        const timeTo = moment(event.fields.to, "hh:mm a")
        var pt = new Date().toLocaleString("en-US", {
          timeZone: "America/Los_Angeles",
        })
        const now = new Date(pt)
        const nowTime = moment({ h: now.getHours(), m: now.getMinutes() })
        return !timeTo.isBefore(nowTime)
      }),
    [todayEvents]
  )

  const timeFilteredOnlyTodayEvents = React.useMemo(
    () =>
      todayEvents &&
      timeFilteredTodayEvents &&
      todayEvents.filter(event => {
        const endDate = new Date(event.fields.eventEndDate)
        const today = new Date()
        if (
          !(
            endDate.getUTCFullYear() === today.getUTCFullYear() &&
            endDate.getUTCMonth() === today.getUTCMonth() &&
            endDate.getUTCDate() === today.getUTCDate()
          )
        ) {
          return timeFilteredTodayEvents.includes(event)
        }
        return true
      }),
    [todayEvents, timeFilteredTodayEvents]
  )

  React.useEffect(() => {
    if (additionalQuery !== false && timeFilteredOnlyTodayEvents)
      findWeekEvents({
        "fields.eventStartDate[lt]": nextSunday,
        "fields.eventEndDate[gte]": today,
        "sys.id[nin]": timeFilteredOnlyTodayEvents
          .map(event => event.sys.id)
          .join(","),
        ...additionalQuery,
      })
  }, [
    additionalQuery,
    findWeekEvents,
    today,
    nextFriday,
    timeFilteredOnlyTodayEvents,
  ])
  React.useEffect(() => {
    if (additionalQuery !== false && timeFilteredOnlyTodayEvents && weekEvents)
      findMonthEvents({
        "fields.eventStartDate[lte]": monthEnd,
        "fields.eventEndDate[gte]": today,
        "sys.id[nin]": [...timeFilteredOnlyTodayEvents, ...weekEvents]
          .map(event => event.sys.id)
          .join(","),
        ...additionalQuery,
      })
  }, [
    additionalQuery,
    findMonthEvents,
    today,
    monthEnd,
    timeFilteredOnlyTodayEvents,
    weekEvents,
  ])
  React.useEffect(() => {
    if (
      additionalQuery !== false &&
      timeFilteredOnlyTodayEvents &&
      weekEvents &&
      monthEvents
    )
      findFutureEvents({
        "fields.eventEndDate[gt]": monthEnd,
        "sys.id[nin]": [
          ...timeFilteredOnlyTodayEvents,
          ...weekEvents,
          ...monthEvents,
        ]
          .map(event => event.sys.id)
          .join(","),
        ...additionalQuery,
      })
  }, [
    additionalQuery,
    findFutureEvents,
    today,
    monthEnd,
    timeFilteredOnlyTodayEvents,
    weekEvents,
    monthEvents,
  ])

  const groupedFutureEvents = React.useMemo(() => {
    if (!futureEvents) return null
    const map = futureEvents.reduce((map, event) => {
      const startDate = new Date(event.fields.eventStartDate)
      const month = startDate.getUTCMonth()
      const year = startDate.getUTCFullYear()
      map[year] = map[year] || {}
      map[year][month] = [...(map[year][month] || []), event]
      return map
    }, {})
    const array = []
    Object.keys(map)
      .sort((a, b) => parseInt(a) - parseInt(b))
      .forEach(year =>
        Object.keys(map[year])
          .sort((a, b) => parseInt(a) - parseInt(b))
          .forEach(month =>
            array.push({
              title:
                new Date(year, month, 1).toLocaleString("en-US", {
                  month: "long",
                }) +
                " " +
                year,
              events: map[year][month],
            })
          )
      )
    setLoading(false)
    return array
  }, [futureEvents])

  return {
    loading,
    todayEvents,
    timeFilteredOnlyTodayEvents,
    timeFilteredTodayEvents,
    weekEvents,
    monthEvents,
    futureEvents,
    groupedFutureEvents,
  }
}

export { useEventCalendarEvents }
