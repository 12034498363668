/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { useAd } from "../hooks/use-ad"
import Link from "./link"
import { useMedia } from "react-use"
import { mediaQueryList } from "../helpers"
import { useIsClient } from "../hooks/use-is-client"

const Ad = ({ desktopAdUrl, mobileAdUrl, ...restProps }) => {
  const desktopAd = useAd(desktopAdUrl)
  const mobileAd = useAd(mobileAdUrl)
  return desktopAd || mobileAd ? (
    <div sx={{ display: "flex", justifyContent: "center" }} {...restProps}>
      {desktopAd && (
        <Link
          sx={{
            display: ["none", "none", "block"],
          }}
          link={desktopAd.redirect_url}
        >
          <img src={desktopAd.image_url} />
        </Link>
      )}
      {mobileAd && (
        <Link
          sx={{ display: ["block", "block", "none"] }}
          link={mobileAd.redirect_url}
        >
          <img src={mobileAd.image_url} />
        </Link>
      )}
    </div>
  ) : null
}

export default Ad
